import { useParams } from "react-router-dom";

import DoctorsContent from "../components/DoctorsContent.jsx/DoctorsContent";

const DoctorsContentPage = () => {
  const params = useParams();

  return (
    <>
      {params.doctorId === "dr-ijeoma-nnabuife" && (
        <DoctorsContent drIjeoma={true} />
      )}
      {params.doctorId === "dr-edidiong-monday-udo" && (
        <DoctorsContent drEdidiong={true} />
      )}
      {params.doctorId === "dr-marbel-onuoha" && (
        <DoctorsContent drMarbel={true} />
      )}
      {params.doctorId === "dr-idorenyin" && (
        <DoctorsContent drIdorenyin={true} />
      )}
      {params.doctorId === "mr-nse-ndinonso-anako" && (
        <DoctorsContent mrNdinonso={true} />
      )}
      {params.doctorId === "dr-idorenyin-mfon" && (
        <DoctorsContent drIdorenyinMfon={true} />
      )}
    </>
  );
};

export default DoctorsContentPage;
