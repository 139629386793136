import doctor from "../assets/doctors/doctor-1.jpg";
import doctor2 from "../assets/doctors/doctor-2.jpg";

import keystone from "../assets/partners/keystone-logo.png";
import moniepoint from "../assets/partners/moniepoint-logo.png";
import uba from "../assets/partners/uba-logo.png";

export const DoctorsForum = [
  {
    Name: "Dr (Lady) Ijeoma Nnabuife",
    img: doctor,
    highlight: "an excellent Frontline Optometrist",
    paragraph:
      "Meet our Frontline Optometrist here at ImagePlus Eye Clinic Dr (Lady) Ijeoma Nnabuife (KSJI) OD, MNOA. She is a certified optometric physician who specializes in vision correction, contact lens Prescription, diagnosing and management of ocular diseases such as dry eye, age related macular degeneration (AMD), glaucoma, diabetic/hypertensive retinopathy, cataract etc. She is not only a primary eye care provider but also vested in public health. She’s aware of patients' overall medical history and deliver eye care accordingly",
    Time: "Monday - Friday 8:00 AM - 5:00 PM",
    Time2: "Saturday",
  },
  {
    Name: "Dr Onita Catherine",
    img: doctor2,
    highlight: "A Professional Doctor",
    paragraph:
      "Meet our Frontline Optometrist here at ImagePlus Eye Clinic Dr (Lady) Ijeoma Nnabuife (KSJI) OD, MNOA. She is a certified optometric physician who specializes in vision correction, contact lens Prescription, diagnosing and management of ocular diseases such as dry eye, age related macular degeneration (AMD), glaucoma, diabetic/hypertensive retinopathy, cataract etc. She is not only a primary eye care provider but also vested in public health. She’s aware of patients' overall medical history and deliver eye care accordingly.",
    Time: "Monday - Friday 8:00 AM - 5:00 PM",
  },
];

export const ImagePartners = [
  {
    title: "keystone",
    img: keystone,
  },
  {
    title: "Moniepoint",
    img: moniepoint,
  },
  {
    title: "UBA",
    img: uba,
  },
];
