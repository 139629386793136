import { Link } from "react-router-dom";

import firstDoctorImg from "../../assets/doctors/doctor-1.jpg";
import secondDoctorImg from "../../assets/doctors/doctor-2.jpg";
import thirdDoctorImg from "../../assets/doctors/doctor-3.JPG";
import fourthDoctorImg from "../../assets/doctors/doctor-4.jpeg";
import fifthDoctorImg from "../../assets/doctors/doctor-5.jpeg";
import sixthDoctorImg from "../../assets/doctors/doctor-6.jpeg";
import React from "react";

const Doctors = () => {
  return (
    <section
      id="doctors"
      className="pt-10 pb-16 px-6 bg-[#2f2f2f] text-white sm:px-12 md:py-16 md:px-20 lg:px-16 xl:px-28"
    >
      <div className="flex flex-col items-center mb-10">
        <h2 className="mb-3 text-center text-3xl font-bold uppercase md:text-4xl">
          Meet Our Doctors
        </h2>
        <div className="w-10 h-[3px] bg-[#f97729]" />
      </div>
      <div className="mb-12">
        <p className="text-xl">
          Here at Imageplus Eye Clinic, we have the most reputed doctors and
          well trained specialists who provide comprehensive care to address all
          your concerns. Book an appointment by{" "}
          <Link className="underline" to="tel:+2349071259643">
            phone
          </Link>{" "}
          or{" "}
          <Link className="underline" to="#book-appointment">
            online.
          </Link>
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 justify-center">
        {/* Doctor 1 */}
        <div className="shadow-2xl">
          <img
            className="w-[370px] h-[350px] rounded-t"
            src={firstDoctorImg}
            alt="Dr Ijeoma"
          />
          <div className="py-2 text-center bg-[#f97729]">
            <h3 className="text-lg lg:text-xl">Dr (Lady) Ijeoma Nnabuife</h3>
            <p className="text-sm lg:text-base">Chief Medical Doctor</p>
          </div>
          <div className="flex flex-col pt-4 bg-white rounded-b">
            <div className="mb-4 px-8 text-[#555] text-sm lg:text-base">
              <div className="flex justify-between border-b pb-2">
                <p>Monday - Saturday</p>
                <p>8:00 AM - 5:00 PM</p>
              </div>
            </div>
            <Link
              className="inline-block mb-6 self-center px-6 py-3 text-[#f97729] text-sm uppercase font-bold border-2 border-[#f97729] rounded-full transition-all hover:text-white hover:bg-[#f97729] lg:px-8 lg:text-base"
              to="doctors/dr-ijeoma-nnabuife"
            >
              Read Profile
            </Link>
          </div>
        </div>

        {/* Doctor 2 */}
        {/* <div className="shadow-2xl">
          <img
            className="w-[370px] h-[350px] rounded-t"
            src={secondDoctorImg}
            alt="Dr Edidiong"
          />
          <div className="py-2 text-center bg-[#f97729]">
            <h3 className="text-lg lg:text-xl">Dr Edidiong Monday Udo</h3>
            <p className="text-sm lg:text-base">Optometrist</p>
          </div>
          <div className="flex flex-col pt-4 bg-white rounded-b">
            <div className="mb-4 px-8 text-[#555] text-sm lg:text-base">
              <div className="flex justify-between border-b pb-2">
                <p>Monday - Saturday</p>
                <p>8:00 AM - 5:00 PM</p>
              </div>
            </div>
            <Link
              className="inline-block mb-6 self-center px-6 py-3 text-[#f97729] text-sm uppercase font-bold border-2 border-[#f97729] rounded-full transition-all hover:text-white hover:bg-[#f97729] lg:px-8 lg:text-base"
              to="doctors/dr-edidiong-monday-udo"
            >
              Read Profile
            </Link>
          </div>
        </div> */}

        {/* Doctor 3 */}
        <div className="shadow-2xl">
          <img
            className="w-[370px] h-[350px] rounded-t"
            src={thirdDoctorImg}
            alt="Dr Marbel Onuoha"
          />
          <div className="py-2 text-center bg-[#f97729]">
            <h3 className="text-lg lg:text-xl">Dr Marbel Onuoha</h3>
            <p className="text-sm lg:text-base">Head Doctor, Aba Branch</p>
          </div>
          <div className="flex flex-col pt-4 bg-white rounded-b">
            <div className="mb-4 px-8 text-[#555] text-sm lg:text-base">
              <div className="flex justify-between border-b pb-2">
                <p>Monday - Saturday</p>
                <p>8:00 AM - 5:00 PM</p>
              </div>
            </div>
            <Link
              className="inline-block mb-6 self-center px-6 py-3 text-[#f97729] text-sm uppercase font-bold border-2 border-[#f97729] rounded-full transition-all hover:text-white hover:bg-[#f97729] lg:px-8 lg:text-base"
              to="doctors/dr-marbel-onuoha"
            >
              Read Profile
            </Link>
          </div>
        </div>

        {/* Doctor 4 */}
        <div className="shadow-2xl">
          <img
            className="w-[370px] h-[350px] rounded-t"
            src={fourthDoctorImg}
            alt="Dr Idorenyin"
          />
          <div className="py-2 text-center bg-[#f97729]">
            <h3 className="text-lg lg:text-xl">Dr Catherine Onita</h3>
            <p className="text-sm lg:text-base">Head doctor, PortHarcourt</p>
          </div>
          <div className="flex flex-col pt-4 bg-white rounded-b">
            <div className="mb-4 px-8 text-[#555] text-sm lg:text-base">
              <div className="flex justify-between border-b pb-2">
                <p>Monday - Saturday</p>
                <p>8:00 AM - 5:00 PM</p>
              </div>
            </div>
            <Link
              className="inline-block mb-6 self-center px-6 py-3 text-[#f97729] text-sm uppercase font-bold border-2 border-[#f97729] rounded-full transition-all hover:text-white hover:bg-[#f97729] lg:px-8 lg:text-base"
              to="doctors/dr-idorenyin"
            >
              Read Profile
            </Link>
          </div>
        </div>

        {/* Doctor 5 */}
        <div className="shadow-2xl">
          <img
            className="w-[370px] h-[350px] rounded-t"
            src={fifthDoctorImg}
            alt="Mr Nse Ndinonso Anako"
          />
          <div className="py-2 text-center bg-[#f97729]">
            <h3 className="text-lg lg:text-xl">Mr Nse Ndinonso Anako</h3>
            <p className="text-sm lg:text-base">General Manager</p>
          </div>
          <div className="flex flex-col pt-4 bg-white rounded-b">
            <div className="mb-4 px-8 text-[#555] text-sm lg:text-base">
              <div className="flex justify-between border-b pb-2">
                <p>Monday - Saturday</p>
                <p>8:00 AM - 5:00 PM</p>
              </div>
            </div>
            <Link
              className="inline-block mb-6 self-center px-6 py-3 text-[#f97729] text-sm uppercase font-bold border-2 border-[#f97729] rounded-full transition-all hover:text-white hover:bg-[#f97729] lg:px-8 lg:text-base"
              to="doctors/mr-nse-ndinonso-anako"
            >
              Read Profile
            </Link>
          </div>
        </div>

        {/* Doctor 6 */}
        <div className="shadow-2xl">
          <img
            className="w-[370px] h-[350px] rounded-t"
            src={sixthDoctorImg}
            alt="dr-idorenyin-mfon"
          />
          <div className="py-2 text-center bg-[#f97729]">
            <h3 className="text-lg lg:text-xl">
              Dr (Mrs) Idorenyin Mfon Etukudoh
            </h3>
            <p className="text-sm lg:text-base">
              deputy medical director and president staff union government
            </p>
          </div>
          <div className="flex flex-col pt-4 bg-white rounded-b">
            <div className="mb-4 px-8 text-[#555] text-sm lg:text-base">
              <div className="flex justify-between border-b pb-2">
                <p>Monday - Saturday</p>
                <p>8:00 AM - 5:00 PM</p>
              </div>
            </div>
            <Link
              className="inline-block mb-6 self-center px-6 py-3 text-[#f97729] text-sm uppercase font-bold border-2 border-[#f97729] rounded-full transition-all hover:text-white hover:bg-[#f97729] lg:px-8 lg:text-base"
              to="doctors/dr-idorenyin-mfon"
            >
              Read Profile
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Doctors;
